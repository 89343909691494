import {z} from 'zod'

import {GetStaticProps} from 'next'

import Metadata from '../components/head/Metadata'
import {ParselyMetadata} from '../components/head/ParselyMetadata'
import IndexBody from '../components/index/IndexBody'
import IndexHero from '../components/index/IndexHero'
import IndexSubnav from '../components/index/IndexSubnav'
import {queries, types} from '../lib/sanity'
import {StoriesSchema} from '../lib/sanity/types'
import BannerAd from '../components/ads/BannerAd'

const HomePageProps = z.object({
  headlines: types.StoriesSchema,
  featuredStory: types.StorySchema,
  categories: z.array(
    z.object({category: z.string(), stories: types.StoriesSchema}),
  ),
  videoStories: StoriesSchema,
})
const Home = (props: z.infer<typeof HomePageProps>) => {
  const {headlines, featuredStory, categories, videoStories} = props
  return (
    <>
      <Metadata
        title="Glance by Eyes On Eyecare | Read the Latest Eyecare Industry News"
        description="Breaking eyecare industry news, curated for busy clinicians. Stay up-to-date on the most important stories impacting optometry & ophthalmology today!"
      />
      <ParselyMetadata
        section="Glance"
        title="Glance by Eyes On Eyecare"
        url="https://glance.eyesoneyecare.com/"
      />
      <div className="container">
        <IndexSubnav />
        <IndexHero headlines={headlines} featuredStory={featuredStory} />
        <div className="pt-0 pb-5 pt-lg-5 pb-lg-0">
          <BannerAd adCode="glance_index_page" />
        </div>
        <IndexBody categories={categories} videoStories={videoStories} />
      </div>
    </>
  )
}

/**
 * getStaticProps queries Sanity for the data needed to render the index page.
 *   - Headlines (most recent stories)
 *   - Featured story
 *   - Trending stories
 *   - Stories by category (most recent stories in every category)
 */
export const getStaticProps: GetStaticProps = async () => {
  const featuredStory = await queries.getFeaturedStory()
  const mostRecentStory = await queries.getMostRecentStory()
  const headlines = await queries.getHeadlineStories(
    8,
    featuredStory ? [featuredStory._id] : [mostRecentStory._id],
  )

  const indexPageCategories = [
    'Business',
    'Research',
    'Pipeline',
    'Products',
    'Legal',
    'Archives',
    'Events',
  ]
  const categories = await Promise.all(
    indexPageCategories.map(async (category) => {
      return {
        category,
        stories: await queries.getRecentStoriesByCategory(category, 4),
      }
    }),
  )

  const videoStories = await queries.getRecentStoriesByTag('Glance Videos', 4)

  return {
    props: {
      headlines,
      // https://app.clickup.com/t/3qtrqbg If no featured story - get the most recent story
      featuredStory: featuredStory || mostRecentStory,
      categories,
      videoStories,
    },
  }
}

export default Home
