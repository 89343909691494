import {z} from 'zod'

import Link from 'next/link'

import {PortableText} from '@portabletext/react'

import {PortableTextSchema, SanityImage} from '../../lib/sanity/types'
import SanityImageComponent from '../images/SanityImageComponent'
import {FeaturedTextComponents} from '../../lib/sanity/portableTextComponents'

interface ContentBlockProps {
  image: z.infer<typeof SanityImage> | null
  imageWidth: number
  imageHeight: number
  imageObjectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  imagePriority?: boolean
  showImage: boolean
  heading: string
  footer: string | null
  body: z.infer<typeof PortableTextSchema> | null
  link: string | null
  flexDirection: string | null
  margin: string
}

export default function ContentBlock({
  image,
  // 1.91 aspect ratio (1200x628 per sanity images)
  imageWidth = 600,
  imageHeight = 314,
  imageObjectFit = 'fill',
  imagePriority = false,
  showImage = true,
  heading,
  title,
  footer,
  body,
  link,
  flexDirection = 'row',
  margin = 'mb-3',
}: Partial<ContentBlockProps> & {title: JSX.Element}) {
  return (
    <div className={`d-flex flex-${flexDirection} ${margin} position-relative`}>
      {showImage && (
        <div style={{minWidth: '80px'}}>
          <SanityImageComponent
            src={image?.asset}
            alt={title.props.children}
            width={imageWidth}
            height={imageHeight}
            className="rounded flex-shrink-0"
            objectFit={imageObjectFit}
            priority={imagePriority}
          />
        </div>
      )}
      <div
        className={`${flexDirection === 'row' && 'd-flex flex-column ms-3'}`}
      >
        {heading && <span className="block-label-primary">{heading}</span>}
        {link ? (
          <Link href={link} legacyBehavior>
            <a className="stretched-link">{title}</a>
          </Link>
        ) : (
          title
        )}
        {footer && (
          <span
            className="text-capitalize text-muted"
            style={{fontSize: '.8rem'}}
          >
            {footer}
          </span>
        )}
      </div>
      {body && (
        <div className="lead text-muted mt-2">
          <PortableText value={body} components={FeaturedTextComponents} />
        </div>
      )}
    </div>
  )
}
