import styles from '../../styles/modules/CTA.module.scss'

export default function CTA(props: {
  headline: string
  buttonUrl: string
  buttonText: string
  copy: string
}) {
  const {headline, buttonUrl, buttonText, copy} = props
  return (
    <div className={`${styles.cta} p-4 border rounded my-4`}>
      <div className="d-flex flex-column flex-lg-row align-items-center">
        <div className="d-flex flex-column align-items-center align-items-lg-start me-lg-5">
          <h1 className="h4">{headline}</h1>
        </div>
        <div className="text-center" style={{minWidth: '30%'}}>
          <a
            className="btn btn-black my-3 my-lg-0"
            href={buttonUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            {buttonText}
          </a>
        </div>
      </div>
      <div className="mt-1 d-flex flex-column align-items-center align-items-lg-start">
        <div className="text-muted">
          <p>{copy}</p>
        </div>
      </div>
    </div>
  )
}
