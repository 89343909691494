import {z} from 'zod'

import {PortableText} from '@portabletext/react'

import {useDate} from '../../lib/hooks/useDate'
import {types} from '../../lib/sanity'
import {FeaturedTextComponents} from '../../lib/sanity/portableTextComponents'
import {Story} from '../../lib/sanity/types'
import styles from '../../styles/modules/Hero.module.scss'
import {trpc} from '../../utils/trpc'
import CTA from '../content/CTA'
import ContentBlock from '../content/ContentBlock'
import {getStorySlug} from '../story/utils'

const HeroProps = z.object({
  headlines: types.StoriesSchema,
  featuredStory: types.StorySchema,
})

export default function IndexHero({
  headlines,
  featuredStory,
}: z.infer<typeof HeroProps>) {
  const {data: trending} = trpc.sanity.getTrendingStories.useQuery() // gets 5 trending stories
  return (
    <div className="row my-4">
      <div className="col-lg-3 order-2 order-lg-1 d-flex flex-column justify-content-between px-4 my-4 my-lg-0">
        <div>
          <h6 className="font-nunito text-muted lead">HEADLINES</h6>
          {headlines.slice(0, 3).map((story, i) => (
            <HeadlineComponent key={story._id} story={story} i={i} />
          ))}
        </div>
        <div className="d-flex justify-content-center">
          {/* Can't use next/link when linking to Algolia feed, yet */}
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a className="btn btn-primary btn-lg" href="/stories">
            Browse All Stories &raquo;
          </a>
        </div>
      </div>
      <div className={`col-lg-6 order-1 order-lg-2 px-4 ${styles.borderHero}`}>
        <h6 className="d-none d-lg-block font-nunito lead text-muted">
          FEATURED
        </h6>
        <ContentBlock
          image={featuredStory.featuredImage}
          imageWidth={
            featuredStory.featuredImage?.asset?.metadata?.dimensions.width
          }
          imageHeight={
            featuredStory.featuredImage?.asset?.metadata?.dimensions.height
          }
          imagePriority
          title={
            featuredStory.titleOverride ? (
              <h1 className="fs-2 mb-n2">
                <PortableText
                  value={featuredStory.titleOverride}
                  components={FeaturedTextComponents}
                />
              </h1>
            ) : (
              <h1 className="fs-1">{featuredStory.title}</h1>
            )
          }
          footer={useDate(featuredStory.dateSlug)}
          body={featuredStory.featuredText}
          link={getStorySlug(
            featuredStory.dateSlug,
            featuredStory.slug.current,
          )}
          flexDirection="column"
        />
        <CTA
          headline="Earn FREE CE 24/7 with Courses by Eyes On Eyecare"
          copy="Explore premium-quality, bite-sized courses featuring leading experts like Cecelia Koetting, OD, FAAO, and Cory Lappin, OD, MS, FAAO, and continuing education covering topics from ocular rosacea treatments to retinal disease."
          buttonText="Check it out"
          buttonUrl="https://courses.eyesoneyecare.com/?utm_campaign=%5Beoe-marketing%5D%20Courses%20product%20launch%202023&utm_source=EOE1&utm_medium=cta"
        />
      </div>
      <div className="col-lg-3 order-3 d-flex flex-column justify-content-between px-4 my-4 my-lg-0">
        <div>
          <h6 className="font-nunito lead text-muted">TRENDING</h6>
          {trending &&
            trending
              .slice(0, 3)
              .map((story) => (
                <TrendingComponent key={story._id} story={story} />
              ))}
        </div>
      </div>
    </div>
  )
}

type HeadlineComponent = (props: {story: Story; i: number}) => JSX.Element
const HeadlineComponent: HeadlineComponent = ({story}) => {
  const date = useDate(story.dateSlug)
  return (
    <ContentBlock
      key={story._id}
      image={story.featuredImage}
      imageWidth={story.featuredImage?.asset?.metadata?.dimensions.width}
      imageHeight={story.featuredImage?.asset?.metadata?.dimensions.height}
      heading={story.category.toUpperCase()}
      footer={date}
      title={
        story.titleOverride ? (
          <h6 className="mb-n2">
            <PortableText
              value={story.titleOverride}
              components={FeaturedTextComponents}
            />
          </h6>
        ) : (
          <h6 className="mb-1">{story.title}</h6>
        )
      }
      link={getStorySlug(story.dateSlug, story.slug.current)}
      flexDirection="column"
    />
  )
}

type TrendingComponent = (props: {story: Story}) => JSX.Element
const TrendingComponent: TrendingComponent = ({story}) => {
  const date = useDate(story.dateSlug)
  return (
    <ContentBlock
      key={story._id}
      image={story.featuredImage}
      imageWidth={story.featuredImage?.asset?.metadata?.dimensions.width}
      imageHeight={story.featuredImage?.asset?.metadata?.dimensions.height}
      heading={story.category.toUpperCase()}
      footer={date}
      title={
        story.titleOverride ? (
          <h6 className="mb-n2">
            <PortableText
              value={story.titleOverride}
              components={FeaturedTextComponents}
            />
          </h6>
        ) : (
          <h6 className="mb-1">{story.title}</h6>
        )
      }
      link={getStorySlug(story.dateSlug, story.slug.current)}
      flexDirection="column"
    />
  )
}
