import useHasMounted from './useHasMounted'
import {displayPublishedDate} from "../utils";

export function useDate(
  datetime: string
) {
  const hasMounted = useHasMounted()
  if (!datetime || !hasMounted) return null
  return displayPublishedDate(datetime)
}
