import {z} from 'zod'

import {PortableText} from '@portabletext/react'

import {useDate} from '../../lib/hooks/useDate'
import {types} from '../../lib/sanity'
import {FeaturedTextComponents} from '../../lib/sanity/portableTextComponents'
import {StoriesSchema, Story} from '../../lib/sanity/types'
import ContentBlock from '../content/ContentBlock'
import {getStorySlug} from '../story/utils'

const BodyProps = z.object({
  categories: z.array(
    z.object({category: z.string(), stories: types.StoriesSchema}),
  ),
  videoStories: StoriesSchema,
})
export default function IndexBody({
  categories,
  videoStories,
}: z.infer<typeof BodyProps>) {
  return (
    <section className="pt-2">
      {videoStories && videoStories.length > 0 && (
        <div className="my-lg-5">
          <h6 className="lead text-uppercase text-muted font-nunito border-bottom pb-3 mb-4">
            Videos
          </h6>
          <div className="row">
            {videoStories.map((story) => (
              <IndexStoryCard story={story} key={story._id} />
            ))}
          </div>
          <div className="d-flex justify-content-end">
            {/* Can't use next/link when linking to Algolia feed, yet */}
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              className="btn btn-primary btn-lg"
              href="/stories/?tags=Glance+Videos"
            >
              Watch More
            </a>
          </div>
        </div>
      )}
      {categories.map(
        (category, rowIndex) =>
          category.stories.length > 0 && (
            <div
              key={category.category}
              className={`${rowIndex !== 1 ? 'my-5' : ''}`}
            >
              <h6 className="lead text-uppercase text-muted font-nunito border-bottom pb-3 mb-4">
                {category.category}
              </h6>
              <div className="row">
                {category.stories.map((story) => (
                  <IndexStoryCard story={story} key={story._id} />
                ))}
                {/* Temporarily do not alterative section styles */}
                {/* since there aren't enough stories to make this */}
                {/* block look good yet. Once there are more stories */}
                {/* every other section on the index page should use */}
                {/* this design: */}
                {/* <>
                    <div className="col-sm-6">
                      <ContentBlock
                        key={category.stories[0]?._id}
                        image={category.stories[0]?.featuredImage}
                        imageWidth={640}
                        imageHeight={360}
                        heading={category.stories[0]?.category.toUpperCase()}
                        footer={displayPublishedDate(
                          category.stories[0]?.publishedAt,
                          'short',
                        )}
                        title={<h3>{category.stories[0]?.title}</h3>}
                        body={category.stories[0]?.featuredText}
                        link={getStorySlug(category.stories[0])}
                        flexDirection="column"
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="h-100 d-flex flex-column justify-content-around">
                        {category.stories.slice(1).map((story) => (
                          <div key={story._id}>
                            <ContentBlock
                              key={story._id}
                              showImage={false}
                              heading={story.category.toUpperCase()}
                              footer={displayPublishedDate(
                                story.publishedAt,
                                'short',
                              )}
                              title={<h4>{story.title}</h4>}
                              link={getStorySlug(story)}
                              flexDirection="column"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </> */}
              </div>
            </div>
          ),
      )}
      <div className="d-flex justify-content-center">
        {/* Can't use next/link when linking to Algolia feed, yet */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a className="btn btn-primary btn-lg" href="/stories">
          Browse All Stories &raquo;
        </a>
      </div>
    </section>
  )
}

type IndexStoryCard = (props: {story: Story}) => JSX.Element
const IndexStoryCard: IndexStoryCard = ({story}) => {
  const date = useDate(story.dateSlug)
  return (
    <div className="col-6 col-sm" key={story._id}>
      <ContentBlock
        key={story._id}
        image={story.featuredImage}
        imageWidth={story.featuredImage?.asset?.metadata?.dimensions.width}
        imageHeight={story.featuredImage?.asset?.metadata?.dimensions.height}
        footer={date}
        title={
          story.titleOverride ? (
            <h5 className="mb-n2">
              <PortableText
                value={story.titleOverride}
                components={FeaturedTextComponents}
              />
            </h5>
          ) : (
            <h5 className="mb-1">{story.title}</h5>
          )
        }
        link={getStorySlug(story.dateSlug, story.slug.current)}
        flexDirection="column"
      />
    </div>
  )
}
